/* eslint-disable import/first */
import React, { useState, useRef, useEffect } from "react";
import Particles from "react-tsparticles";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

const throttle = require("lodash/throttle");

import ScrollDownButton from "../components/ScrollDownButton";
import ParticleConfig from "../particles/particleconfigSubpage";
import ParticleConfigMobile from "../particles/particleconfigSubpageMobile";
import Layout from "../components/layout";
import SEO from "../components/seo";
import AnalyticalKPIs from "../components/AnalyticalKPIs";
import NewsletterSubscribe from "../components/NewsletterSubscribe";
import ContactUs from "../components/ContactUs";
import Container from "../components/Container";
import useDeviceDetect from "../components/useDeviceDetect";
import ButtonArrow from "../components/ButtonArrow";

import SafiaMultiplex from "../images/svg/safia-usp-multiplex.svg";
import SafiaTime from "../images/svg/safia-usp-time.svg";
import SafiaEasy from "../images/svg/safia-usp-easy.svg";
import Extraktion from "../images/svg/extraktion.svg";
import Mix from "../images/svg/mix.svg";
import Measure from "../images/svg/measure.svg";

const IconWrapper = ({ children }) => (
  <div
    className="items-center flex justify-center"
    style={{ height: "110px", width: "100%" }}
  >
    {children}
  </div>
);

const InnovationPage = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const particleDiv = useRef(null);
  const [particleHeight, setParticleHeight] = useState(973 + 72);

  useEffect(() => {
    const resize = throttle(() => {
      setParticleHeight(particleDiv.current.clientHeight + 72);
    }, 200);

    setParticleHeight(particleDiv.current.clientHeight + 72);
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [particleDiv]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const InnovationData = [
    {
      icon: () => (
        <SafiaMultiplex style={{ height: "105px", width: "104.87px" }} />
      ),
      title: t("multiAnalytMethode"),
      description: t("multiAnalytMethodeDesc"),
    },
    {
      icon: () => <SafiaTime style={{ height: "93px", width: "86px" }} />,
      title: t("timeSaving"),
      description: t("timeSavingDesc"),
    },
    {
      icon: () => <SafiaEasy style={{ height: "105px", width: "75px" }} />,
      title: t("mixAndMeasure"),
      description: t("mixAndMeasureDesc"),
    },
  ];

  return (
    <Layout>
      <SEO
        title="Innovation"
        meta={[
          {
            name: "cache-control",
            content: "public, max-age=0, must-revalidate",
          },
        ]}
      />
      <div
        style={{
          position: "relative",
          top: 0,
          left: 0,
        }}
      >
        <Particles
          height={particleHeight}
          className="bg-safiaBlue"
          params={isMobile ? ParticleConfigMobile : ParticleConfig}
        />
        <div ref={particleDiv} className="absolute top-0 w-full">
          <div className="container mx-auto px-8">
            <div style={{ paddingTop: "152px", paddingBottom: "80px" }}>
              <h1 className="w-full text-h1mobile sm:text-h1desktop">
                {t("innovationUSPIh1")}
              </h1>
              <div className="lg:hidden">
                <Slider {...settings} className="mt-22">
                  {InnovationData.map((data) => (
                    <div className="text-center w-full">
                      <IconWrapper>{data.icon()}</IconWrapper>
                      <div style={{ height: "224px" }} className="mt-10">
                        <h2
                          style={{ fontFamily: "Bold" }}
                          className="text-h2mobile sm:text-h2desktop mb-4"
                        >
                          {data.title}
                        </h2>
                        <p className="text-white">{data.description}</p>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="flex-row mt-22 text-center hidden lg:flex place-content-center">
                {InnovationData.map((data, index) => (
                  <div
                    className={`flex flex-col items-center w-full lg:w-331px ${
                      index > 0 && "ml-30px"
                    }`}
                  >
                    <IconWrapper>{data.icon()}</IconWrapper>
                    <div style={{ height: "224px" }} className="mt-10">
                      <h2
                        style={{ fontFamily: "Bold" }}
                        className="text-h2mobile sm:text-h2desktop h-20 mb-2 whitespace-pre-wrap"
                      >
                        {data.title}
                      </h2>
                      <p className="text-white">{data.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <ScrollDownButton selector="#secondsection" marginTop="mt-18" />
            </div>
          </div>
        </div>
        <div id="secondsection">
          <AnalyticalKPIs />
        </div>
        <Container backgroundColor="#100B44">
          <h2 className="text-h2mobile sm:text-h2desktop">
            {t("performanceOfTheAssay")}
          </h2>
          <div className="mt-20 flex flex-row flex-wrap md:justify-evenly">
            <div className="w-full md:w-5/12 mb-12 md:mb-0 flex justify-center">
              <Extraktion />
            </div>
            <div className="w-full md:w-5/12">
              <h3 className="text-h3mobile sm:text-h3desktop mb-4 md:mb-6 text-white">
                {t("extraction")}
              </h3>
              <p className="text-white">{t("extractionDesc")}</p>
            </div>
          </div>
          <div className="mt-20 flex flex-row flex-wrap md:justify-evenly">
            <div className="w-full md:w-5/12 mb-12 md:mb-0 flex justify-center">
              <Mix />
            </div>
            <div className="w-full md:w-5/12">
              <h3 className="text-h3mobile sm:text-h3desktop mb-6 text-white">
                Mix
              </h3>
              <p className="text-white">{t("mixDesc")}</p>
            </div>
          </div>
          <div className="mt-20 flex flex-row flex-wrap md:justify-evenly">
            <div className="w-full md:w-5/12 mb-12 md:mb-0 flex justify-center">
              <Measure />
            </div>
            <div className="w-full md:w-5/12">
              <h3 className="text-h3mobile sm:text-h3desktop mb-6 text-white">
                Measure
              </h3>
              <p className="text-white">{t("measureDesc")}</p>
            </div>
          </div>
          <div className="mt-6 flex flex-row flex-wrap md:justify-evenly">
            <div className="w-full md:w-5/12 md:mb-0 flex justify-center"></div>
            <div className="w-full md:w-5/12">
              <ButtonArrow name={t("product")} linkTo="/produkt/" />
            </div>
          </div>
        </Container>
        <ContactUs />
        {/* <NewsletterSubscribe /> */}
      </div>
    </Layout>
  );
};

export default InnovationPage;
