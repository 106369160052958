import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";
import Container from "./Container";

import PlusIcon from "../images/plus.png";
import MinusIcon from "../images/minus.png";

const Kpi = ({ title, text, index }) => {
  const [collapsed, setCollapsed] = useState(index === 0 ? false : true);
  const props = useSpring({ opacity: collapsed ? 0 : 1 });
  function handleClick(e) {
    e.preventDefault();
    setCollapsed(!collapsed);
  }
  return (
    <div className="flex flex-row">
      <div
        className="cursor-pointer"
        onClick={handleClick}
        style={{
          height: "20px",
          width: "20px",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        {collapsed ? (
          <img
            className="mb-0 mr-5"
            alt="Plus Icon"
            src={PlusIcon}
            height="20px"
            width="20px"
          />
        ) : (
          <img
            className="mb-0 mr-5"
            src={MinusIcon}
            alt="Minus Icon"
            height="6px"
            width="20px"
          />
        )}
      </div>
      <div className="ml-2">
        <h4
          onClick={handleClick}
          className="text-h4mobile sm:text-h4desktop mb-0 cursor-pointer text-safiaBlue"
        >
          {title}
        </h4>
        {!collapsed && (
          <animated.p style={props} className="mt-6">
            {text}
          </animated.p>
        )}
      </div>
    </div>
  );
};

const AnalyticalKPIs = () => {
  const { t } = useTranslation();

  const KPIS = [
    {
      title: t("upToSevenContaminants"),
      text: t("upToSevenContaminantsDesc"),
    },
    {
      title: t("timeAssay"),
      text: t("timeAssayDesc"),
    },
    {
      title: t("readOutTime"),
      text: t("readOutTimeDesc"),
    },
  ];

  return (
    <Container>
      <div className="flex flex-row flex-wrap">
        <div className="w-full lg:w-5/12 lg:mr-24">
          <h2
            style={{ fontFamily: "Bold" }}
            className="text-safiaBlue text-h2mobile sm:text-h2desktop mb-6"
          >
            {t("analyticMethods")}
          </h2>
          <p className="w-full">{t("analyticMethodsFirstDesc")}</p>
          <p className="w-full">{t("analyticMethodsSecondDesc")}</p>
        </div>
        <div className="w-full lg:w-5/12 mt-12 lg:mt-0">
          <h2
            style={{ fontFamily: "Bold" }}
            className="text-safiaBlue text-h2mobile sm:text-h2desktop mb-6"
          >
            {t("safiaInNumbers")}
          </h2>
          {KPIS.map((kpi, index) => (
            <div className="mb-8">
              <Kpi index={index} key={kpi.title} {...kpi} />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default AnalyticalKPIs;
